
















































































import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';

import BaseSettingsPage from './BaseSettingsPage.vue';
import { Stepper, Step } from '@/components/stepper';
import { Profile } from '@/models';
import { ProfileService } from '@/lib/services';
import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import {
  ProfileEditDetails,
  ProfileLogoUpload,
  ProfileEditInvoice,
  ProfileEditEmail,
  ProfileEditSms,
} from '@/components/profile';
import { unwrapError } from '@/lib/helpers';

const sSettings = namespace('settings');

@Component({
  components: {
    Stepper,
    Step,
    Popover,
    ActionButton,
    ActionButtonList,

    ProfileEditDetails,
    ProfileLogoUpload,
    ProfileEditInvoice,
    ProfileEditEmail,
    ProfileEditSms,
  },
})
export default class ProfileEditor extends BaseSettingsPage {
  loading: boolean = false;
  error: string = '';
  profile: Profile;

  newTitle: string = '';
  editingTitle: boolean = false;

  newProfile: boolean = false;

  @sSettings.Getter('hasSms') hasSms: boolean;

  async created(): Promise<void> {
    this.loading = true;
    const profileId = this.$route.params['profileId'];
    if (profileId === 'new') {
      this.profile = new Profile();
      this.loading = false;
      this.editingTitle = true;
      this.newProfile = true;
      return;
    }

    if (!+profileId) {
      this.error = 'Invalid profile given';
      return;
    }

    this.profile = await ProfileService.get(+profileId);
    this.loading = false;
  }

  close(): void {
    this.$router.back();
  }

  editTitle(): void {
    this.newTitle = this.profile.name;
    this.editingTitle = true;
  }

  async saveTitle(): Promise<void> {
    try {
      if (this.newProfile) {
        const newId = await ProfileService.new();
        this.profile.id = newId;
        this.newProfile = false;
        await this.$router.replace({
          name: 'settings-profile-edit',
          params: { profileId: '' + newId },
        });
      }
      await ProfileService.update(this.profile.id, { name: this.newTitle });
      this.profile.name = this.newTitle;
      this.editingTitle = false;
      this.$toaster.success('Profile renamed');
    } catch (e) {
      this.$toaster.error('Profile could not be renamed', unwrapError(e));
    }
  }

  async removeProfile(): Promise<void> {
    try {
      await ProfileService.delete(this.profile.id);
      await this.$router.push({ name: 'settings-profile' });
    } catch (e) {
      this.$toaster.error('Profile could not be deleted', unwrapError(e));
    }
  }
}
